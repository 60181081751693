import React from 'react'

const Footer = () => (
  <footer>
    <div className="bg-primary py-10 flex justify-center text-white tracking-wide">
      <p>© Steven Cotterill 2021</p>
    </div>
  </footer>
)

export default Footer
