import { Link } from 'gatsby'
import React from 'react'
import ToggleDarkMode from './ToggleDarkMode'
import logo from '../images/logo.svg'
import logoWhite from '../images/logo-white.svg'
import gitHub from '../images/github.svg'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showingMobileMenu: false,
    }
  }

  toggleMenu() {
    this.setState({
      showingMobileMenu: !this.state.showingMobileMenu,
    })
  }

  render() {
    return (
      <div
        className={`${
          this.state.showingMobileMenu ? `` : `container mt-2 md:mt-4`
        }`}
      >
        <nav className="flex justify-between items-center">
          <div className="flex flex-shrink-0 items-center mr-8">
            <Link to="/">
              <img src={logo} width="36" height="36" alt="Logo" />
            </Link>
          </div>
          <div className="block lg:hidden flex space-x-7">
            <ToggleDarkMode />
            <button
              onClick={() => this.toggleMenu()}
              className="flex justify-between focus:outline-none"
            >
              <svg
                width="30"
                className="fill-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#0366D6"
              >
                <title>Menu</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>

          <div
            className={`${
              this.state.showingMobileMenu ? `hidden` : ``
            } hidden w-full flex-grow lg:flex lg:items-center lg:w-auto`}
          >
            <div className="md:flex-grow">
              <Link
                to="/about"
                className="block mt-4 mr-8 text-lg font-medium md:inline-block md:mt-0 hover:text-primary"
              >
                About
              </Link>
              <Link
                to="/articles"
                className="block mt-4 mr-8 text-lg font-medium md:inline-block md:mt-0 hover:text-primary"
              >
                Articles
              </Link>
              <Link
                to="/snippets"
                className="block mt-4 mr-8 text-lg font-medium md:inline-block md:mt-0 hover:text-primary"
              >
                Snippets
              </Link>
              <Link
                to="/#contact"
                className="block mt-4 mr-8 text-lg font-medium md:inline-block md:mt-0 hover:text-primary"
              >
                Contact
              </Link>
            </div>
            <div className="flex items-center md:flex-none">
              <a
                href="https://github.com/stevie-c91"
                target="_blank"
                rel="noreferrer noopener"
                className="flex flex-shrink-0 items-center mr-8 hover:opacity-90"
              >
                <img src={gitHub} alt="Github" />
              </a>
              <ToggleDarkMode />
            </div>
          </div>
        </nav>

        <nav
          className={`${
            this.state.showingMobileMenu ? `` : `hidden`
          } w-screen h-screen fixed z-50 top-0 bg-primary`}
        >
          <div className="container flex justify-end">
            <button
              type="button"
              id="closeMobileMenuBtn"
              className="focus:outline-none"
              onClick={() => this.toggleMenu()}
            >
              <svg
                width="30"
                className="mt-6 mr-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col justify-center items-center space-y-6 h-full text-2xl font-bold text-white">
            <Link to="/">
              <img
                src={logoWhite}
                className="hover:opacity-90"
                width="40"
                height="40"
                alt="Logo"
              />
            </Link>
            <Link to="/" className="hover:underline">
              Home
            </Link>
            <Link to="/about" className="hover:underline">
              About
            </Link>
            <Link to="/articles" className="hover:underline">
              Articles
            </Link>
            <Link to="/snippets" className="hover:underline">
              Snippets
            </Link>
            <Link to="/#contact" className="hover:underline">
              Contact
            </Link>
          </div>
        </nav>
      </div>
    )
  }
}

export default Header
