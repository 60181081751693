import React from 'react'
import useDarkMode from 'use-dark-mode'
import lightMode from '../images/light-mode.svg'

import darkMode from '../images/dark-mode.svg'

const icons = {
  light: lightMode,
  dark: darkMode,
}

const ToggleDarkMode = () => {
  const darkMode = useDarkMode(false, {
    classNameDark: 'dark',
    classNameLight: 'light',
  })

  return (
    <div className="flex items-center flex-shrink-0 hover:opacity-90">
      <button
        type="button"
        onClick={darkMode.disable}
        className={darkMode.value === false ? 'hidden' : ''}
      >
        <img src={icons.light} alt="Enable light mode" />
      </button>
      <button
        type="button"
        onClick={darkMode.enable}
        className={darkMode.value === true ? 'hidden' : ''}
      >
        <img src={icons.dark} alt="Enable dark mode" />
      </button>
    </div>
  )
}

export default ToggleDarkMode
